import React, { FC, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import HTag from 'gatsby-theme-dettol/src/components/common/HTag';
import { IExpertiseArticles } from 'gatsby-theme-dettol/src/components/ExpertiseArticles/models';
import 'gatsby-theme-dettol/src/components/ExpertiseArticles/ExpertiseArticles.scss';

import useScreenRecognition from 'gatsby-theme-dettol/src/hooks/useScreenRecognition';

const ExpertiseArticles: FC<IExpertiseArticles> = ({
  title: { regularText, boldText },
  articles,
  btn: { btnText },
  showingOfArticles,
}) => {
  const { windowWidth } = useScreenRecognition();
  const initialArticlesNum = windowWidth < 768 ? 3 : 5;
  const initialLoadNum = initialArticlesNum === 3 ? 3 : 6;

  const computeShowingOfText = (shownCount, totalCount) => {
    return (
      showingOfArticles &&
      showingOfArticles
        .replace(
          '%num%',
          `<strong class="dt-expertise-articles__label-bold">${shownCount}</strong>`
        )
        .replace(
          '%num%',
          `<strong class="dt-expertise-articles__label-bold">${totalCount}</strong>`
        )
    );
  };

  const [articlesShown, setArticlesShown] = useState<ArticleTypes.IArticle[]>(() => {
    return articles.slice(0, initialArticlesNum);
  });
  const [showingOf, setShowingOf] = useState<string | null>(null);

  const loadMoreArticles = (startIdx, endIdx) => {
    const newText = computeShowingOfText(
      articlesShown.length + articles.slice(startIdx, endIdx).length,
      articles.length
    );
    setShowingOf(newText);
    setArticlesShown([...articlesShown, ...articles.slice(startIdx, endIdx)]);
  };

  useEffect(() => {
    setArticlesShown(articles.slice(0, initialArticlesNum));
    const newText = computeShowingOfText(
      initialArticlesNum >= articles.length ? articles.length : initialArticlesNum,
      articles.length
    );

    setShowingOf(newText);
  }, [articles, initialArticlesNum]);

  const CardBox = loadable(() => import('components/common/CardBox'));

  return (
    <section className="dt-expertise-articles" aria-label={`${regularText} ${boldText}`}>
      <HTag underline size={2} regularText={regularText} boldText={boldText} />

      <div className="dt-expertise-articles__row">
        <Row>
          {articlesShown.map((article, index) => {
            const isLarge = index === 0;

            return (
              <Col md={6} lg={isLarge ? 8 : 4} key={article.title}>
                <CardBox
                  title={article.title}
                  link={article.seoCanonicalUrl ? article.seoCanonicalUrl : article.link}
                  intro={article.intro}
                  image={article.image}
                  imageAlt={article.imageAlt}
                  labelTop={
                    article.label
                      ? {
                          children: article.label,
                          type: 'primary',
                        }
                      : undefined
                  }
                  large={isLarge}
                />
              </Col>
            );
          })}
        </Row>
      </div>

      <div className="dt-expertise-articles__btn-holder">
        {showingOf ? (
          <span
            className="dt-expertise-articles__label"
            dangerouslySetInnerHTML={{ __html: showingOf }}
          />
        ) : null}

        {articlesShown.length === articles.length ||
        articles.length <= initialArticlesNum ? null : (
          <Button
            variant="outline-primary"
            className="dt-btn-mw-208"
            onClick={() => {
              loadMoreArticles(articlesShown.length, articlesShown.length + initialLoadNum);
            }}
          >
            {btnText}
          </Button>
        )}
      </div>
    </section>
  );
};

export default ExpertiseArticles;
